
import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiPartnerDetail, apiPartnerAdd, apiPartnerEdit } from '@/api/partner'
import AreaSelect from '@/components/area-select.vue'
import MaterialSelect from '@/components/material-select/index.vue'
import { throttle } from '@/utils/util'

@Component({
    components: {
        AreaSelect,
        MaterialSelect
    }
})
export default class ListsDetail extends Vue {
    /** S Data **/

    // 当前编辑用户的身份ID
    identity: number | null = null; 

    // 添加合作商表单数据
    form: any = {
        name: '',                       // 名称
        account: '',                    // 合作商账号
        password: '',                   // 合作商密码
        confirm_password: '',           // 确认密码
        contacts: '',                   // 联系人
        mobile: '',                     // 联系人手机号
        logo: '',                       // 合作商LOGO
        remark: '',                     // 备注
        status: 1                       // 状态:0-关闭;1-正常;2-冻结
    };

    // 表单校验
    rules = {
        name: [{ required: true, message: '请输入合作商名称', trigger: 'blur' }],
        account: [
            { required: true, message: '请输入合作商账号', trigger: 'blur' }
        ],
        password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            {
                validator: (rule: object, value: string, callback: any) => {
                    !value ? callback(new Error('请输入密码')) : callback()
                },
                trigger: 'blur'
            }
        ],
        confirm_password: [
            { required: true, message: '请再次输入密码', trigger: 'blur' },
            {
                validator: (rule: object, value: string, callback: any) => {
                    if (this.form.password) {
                        if (!value) callback(new Error('请再次输入密码'))
                        if (value !== this.form.password) { callback(new Error('两次输入密码不一致!')) }
                    }
                    callback()
                },
                trigger: 'blur'
            }
        ],
        contacts: [{ required: true, message: '请输入联系人姓名', trigger: ['blur', 'change'] }],
        mobile: [{ required: true, message: '请选择联系人号码', trigger: ['blur', 'change'] }]
    };
    /** E Data **/

    /** S Methods **/
    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement
        refs.validate((valid: boolean): any => {
            if (!valid) return

            if (!this.identity) {
                this.handlePartnerAdd()
            } else {
                this.handlePartnerEdit()
            }
        })
    }

    // 添加合作商
    async handlePartnerAdd(): Promise<void> {
        await apiPartnerAdd({ ...this.form })
        setTimeout(() => this.$router.go(-1), 500)
    }

    // 编辑合作商
    async handlePartnerEdit(): Promise<void> {
        await apiPartnerEdit({ ...this.form, id: this.identity })
        setTimeout(() => this.$router.go(-1), 500)
    }

    // 获取详情
    async getPartnerDetailFunc(): Promise<void> {
        const res: any = await apiPartnerDetail({
            id: this.identity as number
        })
        Object.keys(res).map((key) => {
            this.$set(this.form, key, res[key])
        })
    }
    /** E Methods **/


    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query

        // 编辑模式：初始化数据
        this.identity = +query.id
        if (+query.id) {
            this.getPartnerDetailFunc()
        }

        this.onSubmit = throttle(this.onSubmit, 2000)
    }
    /** E Life Cycle **/
}
